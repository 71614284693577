.devices-widget {
  .device-status {
    position: relative;
    border-color: $danger;
    border-width: 2px;
    &.working {
      border-color: $green;
    }

    .panel-body {
      padding-left: 30px;
    }

    &::before {
      content: "\25CF";
      position: absolute;
      color: $danger;
      font-size: 30px;
      line-height: 34px;
      top: -3px;
      left: 3px;
    }
    &.working::before {
      color: $green;
    }
  }
  .device-legend {
    p{
      position: relative;
      padding-left: 20px;

      span {
        position: absolute;
        font-size: 30px;
        line-height: 15px;
        left: 0;
        right: 0;

        &.failing {
          color: $danger;
        }
        &.working {
          color: $green;
        }
      }
    }
  }
}

.incidents-widget {
  table {

    thead tr:nth-child(n+2) {
      font-size: 0.8em;
      th {
        text-align: center;
      }
    }
  }
}
