@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid";

// Participant Uploads
.module-participantUpload {
  .w2h-admin-list {
    tr.row-invalid,
    tr.row-failure {
      > td {
        @include table-row-variant("danger", theme-color-level("danger", $table-bg-level), theme-color-level("danger", $table-border-level));
      }
      &:hover > td {
        //background-color: darken(@state-danger-bg, 5%)
      }
    }

    .status {
      .status-icon {
        @extend %fa-icon;
        @extend .fas;
      }
      &.status-success {
        color: $success;
        .status-icon::before {
          content: fa-content($fa-var-check);
        }
      }
      &.status-partial {
        color: $warning;
        .status-icon::before {
          content: fa-content($fa-var-exclamation-triangle);
        }
      }
      &.status-pending {
        color: $text-muted;
        .status-icon {
          &::before {
            content: fa-content($fa-var-spinner);
          }
          -webkit-animation: spin 1000ms infinite linear;
          animation: spin 1000ms infinite linear;
        }
      }
      &.status-invalid,
      &.status-failure {
        color: $danger;
        .status-icon::before {
          content: fa-content($fa-var-exclamation-circle);
        }
      }
    }
  }
}
