#sources li.source {

  padding-left: 55px;
  min-height: 40px;
  background-repeat: no-repeat;
  background-size: 35px auto;
  background-position: 10px 10px;

  .list-group-item-heading {
    margin-bottom: 20px;
  }
  &.ActiGraphPedometerConnector, &.ActiGraphSleepConnector {
    background-image: url(../../wayToHealthPlugin/images/device-ActiGraph.png);
  }
  &.AdhereTechConnector {
    background-image: url(../../wayToHealthPlugin/images/device-Adheretech.jpg);
    background-size: 28px auto;
  }
  &.AggregatorConnector {
    background-image: url(../../wayToHealthPlugin/images/device-Aggregator.png);
  }
  &.CleverCapMedicationConnector, &.CleverCapScaleConnector {
    background-image: url(../../wayToHealthPlugin/images/device-Clevercap.png);
  }
  &.ClinstreamVisitsConnector {
    background-image: url(../../wayToHealthPlugin/images/device-PennShield.png);
    background-size: auto 30px;
  }
  &.CSVConnector {
    background-image: url(../../wayToHealthPlugin/images/device-CSV.png);
  }
  &.EpicAppointmentsConnector {
    background-image: url(../../wayToHealthPlugin/images/device-Epic.png);
  }
  &.FitbitPedometerConnector, &.FitbitSleepConnector, &.FitbitHeartRateConnector, &.FitbitActivityConnector {
    background-image: url(../../wayToHealthPlugin/images/device-Fitbit.png);
  }
  &.FindAirConnector {
    background-image: url(../../wayToHealthPlugin/images/device-FindAir.svg);
  }
  &.IHealthGlucometerConnector {
    background-image: url(../../wayToHealthPlugin/images/device-iHealth.png);
  }
  &.OmronBloodPressureConnector {
    background-image: url(../../wayToHealthPlugin/images/device-Omron.png);
  }
  &.MedSignalsMedicationConnector {
    background-image: url(../../wayToHealthPlugin/images/device-Medsignals.jpg);
  }
  &.MisfitShineActivityConnector, &.MisfitShineSleepConnector {
    background-image: url(../../wayToHealthPlugin/images/device-Misfit.png);
  }
  &.QualcommADScaleConnector {
    background-image: url(../../wayToHealthPlugin/images/device-AndMedical.png);
  }
  &.SummarizerConnector {
    background-image: url(../../wayToHealthPlugin/images/device-Summarizer.png);
  }
  &.TwilioConnector, &.TwilioBloodPressureConnector, &.TwilioMMSConnector {
    background-image: url(../../wayToHealthPlugin/images/device-SMS.png);
  }
  &.GoalSettingConnector {
    background-image: url(../../wayToHealthPlugin/images/device-GoalSelection.png);
  }
  &.surveyJSConnector {
    background-image: url(../../wayToHealthPlugin/images/device-Survey.png);
  }
  &.WisepillConnector {
    background-image: url(../../wayToHealthPlugin/images/device-Wisepill.png);
  }
  &.WithingsPedometerConnector, &.WithingsBPCuffConnector, &.WithingsScaleConnector, &.WithingsSleepConnector, &.WithingsHeartRateConnector{
    background-image: url(../../wayToHealthPlugin/images/device-Withings.jpg);
  }
  &.BeamConnector {
    background-image: url(../../wayToHealthPlugin/images/device-Beam.png);
  }
  &.PropellerHealthConnector {
    background-image: url(../../wayToHealthPlugin/images/device-propeller.svg);
  }
}

#sources,
.source,
#source-new {
  .advanced-fields {
    .card-title {
      cursor: pointer;
      .fa-chevron-down {
        display: none;
      }
      &.collapsed {
        .fa-chevron-up {
          display: none;
        }
        .fa-chevron-down {
          display: inline-block;
        }
      }
    }
  }
}
// aggregator fields
.aggregator_source_row {
  margin-bottom: $spacer;
  .form-control {
    margin-right: $spacer; // on purpose
    min-width: 10%;
  }
}