@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid";

.collapse-caret {
  &.collapsed:before {
    content: fa-content($fa-var-caret-right);
  }

  &:before {
    @extend .fas;
    @extend %fa-icon;
    //line-height: 1em;
    content: fa-content($fa-var-caret-down);
    font-size: .8em;
    vertical-align: top;
    top: .2em;
    position: relative;
    margin-right: 5px;
  }
}
