@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "node_modules/bootstrap/scss/variables";
@import "variables";

// mimic chosen-select styling before it is initialized and set by JS
.chosen-select, select[class*='chosen_'] {
  overflow: hidden !important;
  height: 2.5em !important;

  &[multiple] {
    height: 3em !important;
    border-radius: 4px !important;
  }
}

// overwrite vue-multiselect highlight color to match our blue
.multiselect .multiselect__element .multiselect__option--highlight {
  @include gradient-bg($dropdown-link-hover-bg);
  color: inherit;
}

// fix to have chose selects inline and not 100% width
.form-inline {
  .chosen-container {
    width: auto !important;
    margin-left: map-get($spacers, 1);
    margin-right: map-get($spacers, 1);
  }
}
// fix the single-deselect X icon positioning
.chosen-container-single .chosen-single abbr.search-choice-close {
    top: 10px !important;
}

.form-inline .form-inline-table {
  position: absolute;
  left: 150px;
}

//.help-block styling for text above input
.form-text {
  margin-top: 0;
  margin-bottom: 5px;
  color: $gray-600;
  font-size: 0.8em;
}

.has-error{
  label,
  .form-control,
  .help-block {
    color: #a94442 !important;
  }
}


// ie: help text and additional inputs below a checkbox
.field-options {
  margin-left: 20px;
}
.checkbox .help-block {
  margin-left: 20px;
  font-size: 0.8em;
}
.option-change {
  cursor: pointer;
}

ul.error-list {
  font-size: 90%;
  color: #a94442; //mixin ?
  margin: 10px;
  border-left: 5px solid #a94442;
  padding-left: 15px;
}

ul.error-list li {
    list-style-type: none;
}

.input-numeric {
  max-width: 30px;
  &.form-control {
    max-width: 60px;
  }
}

.input-time {
  max-width: 55px;
  &.form-control {
    max-width: 100px;
  }
}

//todo better spot for this
.input-group {
  .input-clear { // clear icon in input
    position: absolute;
    right: 50px;
    top: 0;
    bottom: 0;
    height: 14px;
    margin: auto;
    font-size: 14px;
    cursor: pointer;
    color: #ccc;
    z-index: 999;
  }
}

// overwrite a-forms styles
.radio_list {
  padding-left: 0;
  list-style: none;
  li,
  input {
    float: none;
  }
  li label {
    padding-left: 5px;
    font-weight: 500;
  }
}


.form-group[data-filter-field] {
  br {
    display: none;
  }

  .sfWidgetFormFilterInput, .w2hWidgetDateRange {
    label[for*="_is_empty"] {
      margin-top: 5px;
      font-weight: normal;
    }
  }
}

// remove bootstrap's odd styles
fieldset {
  border: 1px solid #c0c0c0;
  margin-bottom: $form-group-margin-bottom;
  padding: map-get($spacers, 1) map-get($spacers, 2);

  legend {
    max-width: none;
    width: auto;
    border-bottom: none;
    padding: 0 map-get($spacers, 2);

    label {
      max-width: none;
      width: auto;
      font-weight: normal;
    }
  }
}


// Should this be in a different less file?
.icon-spin {
    -webkit-animation: spin 1000ms infinite linear;
    animation: spin 1000ms infinite linear;
}
.btn-loading {
  &:before {
    @extend .fas;
    @extend %fa-icon;
    position: relative;
    top: 1px;
    content: fa-content($fa-var-sync);
    animation: spin 1000ms infinite linear;
    margin-right: 5px;
  }

 .fa {
   // hide the icon inside the button
    display: none;
  }

  // text-less elements will have space to the right of the loading icon, remove it
  & > .text-hide {
    margin-left: -5px;
  }
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

// TODO: Find a way to make this not mess up the text
.chosen-container-multi .chosen-choices li.search-choice span {
    max-width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
}
.form-group {
  position: relative;
}

.form-group > .emojipickicon {
  position: absolute;
  top: 0;
  right: 5px;
  z-index: 2;
  width: 35px;
  height: 35px;
  background: url($emoji-icon) no-repeat right 5px top 5px;
  background-size: 20px 20px;
}

.form-group > label ~ .emojipickicon {
  position: absolute;
  top: 25px;
  right: 5px;
  z-index: 2;
  width: 35px;
  height: 35px;
  background: url($emoji-icon) no-repeat right 5px top 5px;
  background-size: 20px 20px;
}

.form-group > .help-block ~ .emojipickicon {
  position: absolute;
  top: 45px;
  right: 5px;
  z-index: 2;
  width: 35px;
  height: 35px;
  background: url($emoji-icon) no-repeat right 5px top 5px;
  background-size: 20px 20px;
}

.input-group.form-group > .emojipickicon {
  position: absolute;
  top: 0;
  right: 65px;
  z-index: 999;
  width: 35px;
  height: 35px;
  background: url($emoji-icon) no-repeat right 5px top 5px;
  background-size: 20px 20px;
}

.cke_editable {
  padding: 0 10px;
}

.input-toggle {
  position: relative;

  // hide it behind the toggle
  input {
    position: absolute;
    top: 4px;
    left: 1px;
    opacity: 0;
    margin: 0;
  }

  .toggle-slider {
    position: relative;
    display: inline-block;
    width: 33px;
    height: 20px;
    margin-bottom: .1em;
    vertical-align: middle;
    background-color: $gray-600;
    cursor: pointer;
    transition: .4s;
    border-radius: $custom-control-indicator-size;

    &:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 3px;
      bottom: 2px;
      background-color: white;
      transition: .4s;
      border-radius: 50%;
    }
  }

  input:checked + .toggle-slider {
    background-color: $primary;

    &:before {
      transform: translateX(11px);
    }
  }

  input:disabled + .toggle-slider {
    opacity: .6;
    cursor: not-allowed;
  }
}

.study_analytics_datasets_sources {
  font-weight: bold;
  .w2h-admin-form-container {
    padding-top:10px;
    display: table;
    div.form-group {
      display: table-row;
      padding-top: 10px;
    }
    label {
      font-weight: normal;
      display: table-cell;
    }
    .form-check-label {
      padding-right:20px
    }
  }
}
