#enrollmentSteps {
  counter-reset: enrollmentsteps;

  .enrollmentStep {
    min-height: 75px;

    .list-group-item-heading {
      margin-top: 0;
    }

    &::before {
      counter-increment: enrollmentsteps;
      content: counter(enrollmentsteps) ".";
      float: left;
      font-size: $h3-font-size;
      color: $gray-light;
      line-height: $headings-line-height;
    }

    .normalview,
    .editview {
      padding-left: 40px;
    }

    .disabledview {
      opacity: 0.7;
    }

    .status-indicator {
      float: right;
    }

    &.sort:not(.disabled):hover {
      background-color: $gray-lighter;
      cursor: move;
    }
  }
}

.participant-enrollment li {

  &.active,
  &.active:hover {
    color: inherit;
    background-color: theme-color-level("success", $alert-bg-level);
    border-color: theme-color-level("success", $alert-border-level);
  }
  &.future {
    // background-color: @gray-lighter;
    opacity: 0.6;
  }

  .event .rules {
    display: none;
  }

  // leave room for actions
  &.event li.name {
    padding-right: 250px;
  }

  &.collapsable-events {
    .fa-chevron-up {
      display: none;
    }
    &.collapsed {
      .fa-chevron-down {
        display: none;
      }
      .fa-chevron-up {
        display: inline-block;
      }
    }
  }

}

// this should maybe move to study once we make a less file for that?
// fix the table used by weights in randomization configuration
.study_randomizer_weights table {
  th,
  td {
    padding-right: 20px;
    padding-bottom: 10px;

    label {
      font-weight: 500;
    }
  }
}
