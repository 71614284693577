@import "node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular";

.module-epicEmbed {
  // hide detail link to prevent epicEmbed users from navigating to the main site
  .detail-link {
    display: none;
  }

  // If we decide to fix image attachments in the embed, delete this
  #uppy-select-files {
    display: none;
  }

  // hide redundant headers from pages pulled from admin
  .panel-inbox .card-body h2,
  .panel-enrollment .card-body h2 {
    display: none;
  }

  .panel-group .panel-heading + .panel-collapse > .list-group,
  .panel-group .panel-heading + .panel-collapse > .card-body {
    border-top: none;
  }


  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:focus,
  .nav-tabs > li.active > a:hover {
    color: #2e2f32;
    background-color: #f5f5f5;
  }

  #programTabs {
    [data-refresh-program] {
      cursor: pointer;
      display: none;
    }

    li:hover [data-refresh-program] {
      display: inline;
    }

  }


  .program-detail-header {
    border-bottom: 1px solid #ddd;
    padding: 10px;
    margin-top: 0;
    color: #2e2f32;
    background-color: #f5f5f5;
  }

  .program-inactive {
    color: #999;
  }

  .program-star {
    color: #f2c100;

    .icon {
      // It's always a star
      @extend %fa-icon;

      &:before {
        content: fa-content($fa-var-star);
      }

      // Normally it's a regular (empty) star
      @extend .far;
    }

    &[data-starred-program] .icon {
      // If it's starred, it's a solid (filled) star
      @extend .fas;
    }
  }

  // hide in table by default
  tr .program-star {
    opacity: 0;

    // show if starred
    &[data-starred-program] {
      opacity: 1;
    }
  }

  // show if hover on row
  tr:hover .program-star {
    opacity: 1;
  }

  // use in panels to avoid breaking words by letter
  .wrap-word {
    word-wrap: normal;
  }
}
