.participant-header {
  margin-top: map-get($spacers, 3);
  margin-bottom: map-get($spacers, 1);

  h1,
  h4,
  h5,
  .btn-group,
  .participant-prefs,
  .nav {
    padding: 0 map-get($spacers, 3);
  }

  .btn-group {
    margin: $spacer 0;
  }

  h5 {
    margin-top: 5px;
    margin-bottom: 5px;

    label {
      margin-bottom: 0;
    }
  }
}

.partner-status {
  &.Requested {
    color: blue;
  }

  &.Active {
    color: green;
  }

  &.Disabled {
    color: red;
  }
}

.overview-table td {
  padding-left: map-get($spacers, 2);
  padding-right: map-get($spacers, 2);

  .progress {
    min-width: 8rem;
  }

  &.overview-status {
    max-width: 15rem;
  }
}
