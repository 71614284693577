body.action-triage {
  .sidebar {
    width: 300px;
    flex: 0 0 300px;
    -ms-flex: 0 0 260px;

    &.slide {
      margin-left: (-(($sidebar-width-base * 0.8) - 20));
    }
  }

  #content-wrapper .sidebar + .container-fluid {
    max-width: calc(100% - (#{$sidebar-width-base} * 0.8));
  }

  #content-wrapper .sidebar.slide + .container-fluid {
    max-width: calc(100% - 20px);
  }
}

.triage-view-columns {
  border-top: 1px solid $list-group-border-color;
  margin-top: map-get($spacers, 2);

  .participant-list-column {
    border-right: 1px solid $list-group-border-color;
  }
}

.participant-list .list-group {
  margin-left: -15px;
  margin-right: -16px;
  overflow-x: hidden;

  .list-group-item {
    &.active .list-group-item-text {
      color: lighten($list-group-active-bg, 30%);
    }

    &:first-child {
      @include border-top-radius(0);
    }

    &:last-child {
      @include border-bottom-radius(0);
    }
  }
}

.participant-pane {
  .snapshot {
    margin: map-get($spacers, 2) 0;
  }

  .batch-action-buttons {
    margin-top: map-get($spacers, 2);
    margin-bottom: map-get($spacers, 2);

    .btn {
      padding: map-get($spacers, 1) map-get($spacers, 3);
    }
  }
}

.participant-pane .participant-profile {
  .list-group-item {
    border: none;
    box-shadow: none;
    padding: 0;
    white-space: nowrap;
  }


}
