// WTH Navigation
.navbar {
  margin-bottom: 0;
  padding: 0 $spacer;

  &.navbar-dark .navbar-nav .nav-link {
    color: $white;
  }

  .navbar-brand {
    width: 210px;
    height: 50px;
    background: url(../../wayToHealthPlugin/images/w2h_logo_white.png);
    background-repeat: no-repeat;
    background-size: 180px;
    background-position: 15px 50%;
  }

  .navbar-right {
    margin-right: 0;
  }

  .dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  //@media (min-width: @screen-sm-min) {


    //.navbar-collapse {
    //  display: flex !important;
    //}
    //
    .navbar-main,
    .navbar-user {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .study-list {
      flex: 0 0 auto;
      font-size: $font-size-lg;
      &.navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 5px;
      }
      .dropdown-menu {
        max-height: 400px;
        overflow: auto;
      }
    }
    .primary-nav {
      flex: 0 0 auto;
      &.navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .dropdown:hover:hover > .dropdown-menu {
        display: block;
      }
    }


    .user-nav {
      flex: 0 0 auto;
      align-self: flex-end;
      &.navbar-nav>li>a {
        padding-bottom: 10px;
      }
    }
    .nav-search {
      flex: 0 0 auto;
      margin-bottom: 10px;
      display: flex;

      .form-control {
        flex: 1 0 100px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
      }
      .dropdown {
        flex: 0 0 auto;
        .btn {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0
        }
        li.disabled > a {
          pointer-events: none;
        }
      }
    }

  //}

  // reflect environment
  .dev & {
    background-color: $header-color-dev !important;
    border-color: darken($header-color-dev, 6.5%);

    .navbar-brand {
      background-image: url(../../wayToHealthPlugin/images/w2h_logo_dev.png);
    }

    .navbar-nav>.open>a,
    .navbar-nav>.active>a {
      color: darken(#FFF, 10%) !important;
      background-color: darken($header-color-dev, 10%) !important;
    }

    .navbar-nav>li>a:hover,
    .navbar-nav>li>a:focus {
      color: darken(#FFF, 10%) !important;
    }
    .dropdown-menu>.active>a {
      color: darken(#FFF, 10%) !important;
      background-color: darken($header-color-dev, 10%) !important;
    }
  }
  .staging & {
    background-color: $header-color-staging !important;
    border-color: darken($header-color-staging, 6.5%);

    .navbar-brand {
      background-image: url(../../wayToHealthPlugin/images/w2h_logo_staging.png);
    }

    .navbar-nav>.open>a,
    .navbar-nav>.active>a {
      color: darken(#FFF, 10%) !important;
      background-color: darken($header-color-staging, 10%) !important;
    }

    .navbar-nav>li>a:hover,
    .navbar-nav>li>a:focus {
      color: darken(#FFF, 10%) !important;
    }
    .dropdown-menu>.active>a {
      color: darken(#FFF, 10%) !important;
      background-color: darken($header-color-staging, 10%) !important;
    }
  }
  .testing & {
    background-color: $header-color-testing !important;
    border-color: darken($header-color-testing, 6.5%);

    .navbar-brand {
      background-image: url(../../wayToHealthPlugin/images/w2h_logo_testing.png);
    }

    .navbar-nav>.open>a,
    .navbar-nav>.active>a {
      color: darken(#FFF, 10%) !important;
      background-color: darken($header-color-testing, 10%) !important;
    }

    .navbar-nav>li>a:hover,
    .navbar-nav>li>a:focus {
      color: darken(#FFF, 10%) !important;
    }
    .dropdown-menu>.active>a {
      background-color: darken($header-color-testing, 10%) !important;
    }
  }
}