#qframe
{
  display: none;
  min-height: calc(95em);
}

body.qualtrics-edit
{
  #qualtrics-nav-action
  {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100px;
  }

  .navbar-main
  {
    padding-top: 10px;
    align-items: center;
    text-align: center;
    color: white;

    a:link, a:visited
    {
      color: white;
      text-decoration: underline !important;
    }
  }

  & > .w2h-wrapper
  {
    height: 100%;
  }
}


#qualtrics-source-new
{
  form.editview.create .source_qualtrics_SurveyID,
  form.editview.create .source_qualtrics .a-form-errors
  {
    display: none;
  }
}
