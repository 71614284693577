// these variables aren't used anywhere else so they live here
$card-red: #881920;
$card-yellow: #E9AA00;
$card-green: #3E8B27;
$card-orange: #B96124;
$card-purple: #440740;
$card-blue: #3A4383;

$card-default-bg: #F0F0F0;
$card-resources-bg: #E9AA00;
$card-contact-bg: #0F9900;
$card-shadow: #EDEDED;

@mixin card-theme($color) {
  border-color: $color;
  h4 {
    color: $color;
  }
}

// // extra scoping needed
.guide-sections .guide-section {
  overflow: hidden;
  padding-bottom: 20px;
  border-bottom: 1px solid #666;

  &:last-child {
    border-bottom: none;
  }

  &.collapsed {
    padding-bottom: 4px;

    > h3 .arrow {
      border-left: 7px solid $primary;
      border-bottom: 7px solid transparent;
      border-top: 7px solid transparent;
      margin-bottom: -1px;
    }

    .guide-card {
      display: none !important;
    }
  }

  // section headers
  > h3 {
    margin: 16px 0;
    color: $primary;
    user-select: none;
    cursor: pointer;

    .arrow {
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 7px solid $primary;
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
      margin-left: 6px;
      margin-bottom: 2px;
    }
  }

  .guide-card {
    position: relative;

    width: 100%;
    min-height: 440px;
    // flex: 0 250px;
    padding: 10px 20px;
    padding-bottom: 70px;
    border-top: 5px solid #666; // default of grey
    box-shadow: 5px 5px 5px #EDEDED;
    background-color: #f2f2f2;

    background: $card-default-bg;
    vertical-align: top;
    margin-bottom: 20px;

    h4 {
      height: 30px;
      padding-right: 35px;
      margin: 6px 0 4px;
    }

    input[name=skipped] {
      margin-right: 4px;
    }

    &.complete {
      background: $card-default-bg url(../../wayToHealthPlugin/images/circled-check.png) no-repeat 96% 10px;
      background-size: 30px 30px;
    }

    &.locked {
      background: $card-default-bg url(../../wayToHealthPlugin/images/padlock.png) no-repeat 95% 10px;
      background-size: 23px 28px;
      cursor: not-allowed;

      input {
        cursor: not-allowed;
      }
    }

    &.final {
      background: #FFF url(../../wayToHealthPlugin/images/spaceship.png) no-repeat 0 15px;
      background-size: 55px 55px;
      box-shadow: none;
      border-top: none;
      text-align: center;

      h3 {
        margin: 50px 0 0 30px;
        font-size: 21px;
        font-weight: bold;
      }

      p {
        margin: 0 25px;
      }

      &.hide {
        display: none;
      }
    }

    .card-footer {
      position: absolute;
      bottom: 10px;
      left: 50%;

      -webkit-transform: translate(-50%);
      -moz-transform: translate(-50%);
      -o-transform: translate(-50%);
      -ms-transform: translate(-50%);
      -sand-transform: translate(-50%);

      & input {
        margin-left: 5px;
      }
    }

    .card-button {
      min-width: 240px;
      margin: 5px;


      &.red {
        //.button-variant(@btn-danger-color; @btn-danger-bg; @btn-danger-border);
      }
    }

    &.red {
      @include card-theme($card-red)
    }

    &.yellow {
      @include card-theme($card-yellow)
    }

    &.green {
      @include card-theme($card-green)
    }

    &.orange {
      @include card-theme($card-orange)
    }

    &.purple {
      @include card-theme($card-purple)
    }

    &.blue {
      @include card-theme($card-blue)
    }
  }
}


.guide-footer {
  margin-top: 10px;

  .guide-callout {
    min-height: 110px;
    padding: 15px 15px 5px 55px;
    margin-bottom: 10px;

    color: #333;
    background-repeat: no-repeat;
    background-position: 10px 50%;

    .buttons {
      float: right;
      width: 130px;

      li {
        margin-bottom: 10px;

        a {
          text-align: left;
        }
      }

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    // links in text
    p {
      a:link,
      a:visited,
      a:hover,
      a:active {
        color: #FFF;
        text-decoration: none;
      }
    }
  }

  .guide-resources {
    background-color: $card-resources-bg;
    background-image: url(../../wayToHealthPlugin/images/lightning-bolt.png);
  }

  .guide-contact {
    padding-left: 72px;
    background-color: $card-contact-bg;
    background-image: url(../../wayToHealthPlugin/images/paper-airplane.png);
  }
}

.highlight-anim2 {
  animation: pulse 1.5s;
  -webkit-animation-iteration-count: 2; /* Safari 4.0 - 8.0 */
  animation-iteration-count: 2;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes shake {
  from {
    -webkit-transform: rotate(7deg);
  }
  to {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(-7deg);
  }
}