@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans-LightItalic.eot');
  src: url('../../fonts/OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/OpenSans-LightItalic.woff2') format('woff2'),
  url('../../fonts/OpenSans-LightItalic.woff') format('woff'),
  url('../../fonts/OpenSans-LightItalic.ttf') format('truetype'),
  url('../../fonts/OpenSans-LightItalic.svg#OpenSans-LightItalic') format('svg');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans-Light.eot');
  src: url('../../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/OpenSans-Light.woff2') format('woff2'),
  url('../../fonts/OpenSans-Light.woff') format('woff'),
  url('../../fonts/OpenSans-Light.ttf') format('truetype'),
  url('../../fonts/OpenSans-Light.svg#OpenSans-Light') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans-LightItalic.eot');
  src: url('../../fonts/OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/OpenSans-LightItalic.woff2') format('woff2'),
  url('../../fonts/OpenSans-LightItalic.woff') format('woff'),
  url('../../fonts/OpenSans-LightItalic.ttf') format('truetype'),
  url('../../fonts/OpenSans-LightItalic.svg#OpenSans-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans-Light.eot');
  src: url('../../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/OpenSans-Light.woff2') format('woff2'),
  url('../../fonts/OpenSans-Light.woff') format('woff'),
  url('../../fonts/OpenSans-Light.ttf') format('truetype'),
  url('../../fonts/OpenSans-Light.svg#OpenSans-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans-BoldItalic.eot');
  src: url('../../fonts/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/OpenSans-BoldItalic.woff2') format('woff2'),
  url('../../fonts/OpenSans-BoldItalic.woff') format('woff'),
  url('../../fonts/OpenSans-BoldItalic.ttf') format('truetype'),
  url('../../fonts/OpenSans-BoldItalic.svg#OpenSans-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans-Regular.eot');
  src: url('../../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/OpenSans-Regular.woff2') format('woff2'),
  url('../../fonts/OpenSans-Regular.woff') format('woff'),
  url('../../fonts/OpenSans-Regular.ttf') format('truetype'),
  url('../../fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans-Bold.eot');
  src: url('../../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/OpenSans-Bold.woff2') format('woff2'),
  url('../../fonts/OpenSans-Bold.woff') format('woff'),
  url('../../fonts/OpenSans-Bold.ttf') format('truetype'),
  url('../../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans-Italic.eot');
  src: url('../../fonts/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/OpenSans-Italic.woff2') format('woff2'),
  url('../../fonts/OpenSans-Italic.woff') format('woff'),
  url('../../fonts/OpenSans-Italic.ttf') format('truetype'),
  url('../../fonts/OpenSans-Italic.svg#OpenSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

