/* SURVEYJS ============================ */
// turn radios and checkboxes into buttons on mobile
@include media-breakpoint-down(md) {
  .sv_qcbc {
    width: 100%;

    .checkbox,
    .radio,
    .sv_q_matrix {
      &.checked label {
        background-color: theme-color-level("primary", $alert-bg-level);
        color: #fff;
      }

      // labels for a radio or the containing tr for a matrix cell
      label, tr[title] {
        width: 100%;
        padding: 10px 10px;
        border: 1px solid #ccc;
        margin-bottom: 5px;
        background: #eee;
        border-radius: 5px;
        box-shadow: 1px 1px 5px #ccc;

        // overwrite some weird stuff bootstrap is doing
        input[type=checkbox],
        input[type=radio] {
          position: relative;
          margin-left: 0;
          // visibility: hidden;
        }

        .sv_q_checkbox_other,
        .sv_q_radiogroup_other {
          width: 100%;
          margin: 6px 0;
          height: 2.5em;
        }
      }
    }
  }
  // Fix space before label on a matrix question
  .sv_main .sv_container .panel-body.card-block .sv_row .sv_qstn table.sv_q_matrix td::after {
    margin-left: .5em;
    font-weight: bold;
  }
}


// Fix display of dropdowns in modal
.svd_container .svd_content .svd_custom_select select, .svd_container .modal-dialog .svd_custom_select select {
  appearance: auto !important;
}

// for some reason, there's no space after radio buttons
.sv_qcbc .radio label input[type=radio] {
  margin-right: 5px;
}

// hacks for bootstrap4 things that changed. Most or all of these should be removed when we upgrade surveyjs
.svd_questions_editor {
  padding-left: 15px;
  padding-right: 15px;
}

.sv_complete_btn {
  @extend .btn-default;
}

#surveyquestioneditorwindow {
  .modal-header {
    // Put the title to the left and button to the right, even thought that's the reverse of the DOM
    @extend .flex-row-reverse;
  }

  .svd-accordion-tab-header .icon-toolbox-arrow {
    margin-top: 10px;
  }


  // Fix input group around validation dropdown (add/trash buttons)
  .propertyeditor-validators {

    .input-group-addon {
      @extend .input-group-text;
      &.first-addon {
        @include border-right-radius(0);
      }
    }

    .svd_custom_select {
      width: auto;
    }
  }
}

// SurveyJS signature pad
.sjs_sp_container canvas {
  background-color: white;
  border: 1px dashed black;
}

