/*
Use markup as follows for an easy Youtube preview, replace ztYwss6ZuoA with video ID
<div class="yt-video">
  <img src="https://img.youtube.com/vi/ztYwss6ZuoA/maxresdefault.jpg" />
  <a href="https://www.youtube.com/watch?v=ztYwss6ZuoA" target="_blank"></a>
</div>
 */

.yt-video
{
  position: relative;
  margin-bottom: $spacer;

  img
  {
    width: 100%;
  }

  a,
  a:hover
  {
    position: absolute;
    top: 0;
    left: 0;

    display: block;
    height: 100%;
    width: 100%;

    background: url("../../wayToHealthPlugin/images/yt-play-button-grey.png") no-repeat;
    background-size: 30% auto;
    background-position: center;
  }

  a:hover 
  {
    background-image: url("../../wayToHealthPlugin/images/yt-play-button.png");
  }
}