$sidebar-width-base: 375px;

/* LAYOUT + NAVBAR + CONTENT AREA */
html,
body {
  height: 100%;
}

.w2h-wrapper
{
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

#content-wrapper {
  flex: 1 0 auto;
  display: flex;

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;

    .container {
      width: 100%;
    }
  }

  .container-fluid {
    flex: 1 0 auto;
    max-width: 100%;
  }
  #content-container {
    padding-top: 15px;
  }
  .container-fluid,
  .container {
    padding-bottom: 15px;
  }

  .sidebar + .container-fluid {
    margin-right: 0;
    transition: max-width 0.5s ease;
    max-width: calc(100% - #{$sidebar-width-base});

    @include media-breakpoint-down(md) {
      max-width: calc(100% - (#{$sidebar-width-base * 0.8}));
    }

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }

  .container > .alert,
  .container-fluid > .alert {
    margin-top: 10px;
  }
}

.sidebar {
  position: relative;
  flex: 0 0 $sidebar-width-base;
  -ms-flex: 0 0 ($sidebar-width-base - 40);
  width: $sidebar-width-base;
  background-color: #F7F7F7;
  align-self: stretch;
  padding: $input-padding-y-lg 20px;
  transition: margin-left 0.5s ease, background-color 0.5s, box-shadow 0.5s;
  &.slide {
    margin-left: (-($sidebar-width-base - 20));
    background-color: $gray-lighter;
    box-shadow: 0px 0px 8px 3px $gray-light;

    @include media-breakpoint-down(md) {
      margin-left: (-(($sidebar-width-base * 0.8) - 20));
    }

    #content-wrapper & + .container-fluid {
      max-width: calc(100% - 20px);
    }
  }

  .vertical > * {
    margin-bottom: 10px;
  }

  @include media-breakpoint-down(md) {
    width: $sidebar-width-base * 0.8;
    flex: 0 0 ($sidebar-width-base * 0.8);
    -ms-flex: 0 0 (($sidebar-width-base * 0.8) - 40);
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
    flex: 1 0 auto;
  }
  > * {
    margin-bottom: 10px;
  }
}
