$primary:           #01256e; //WTH Blue
//$secondary:         #045ea7; //WTH lighter blue
//$success:         #045ea7; //WTH lighter blue
//$info:            #f2c100; //WTH Yellow
//$warning:         #ac3c00; //WTH Darker Orange
$danger:          #95001a; //WTH Red

$green:                 #008300;

$font-size-base: .875rem; //14px
$link-color: #045ea7;
$h4-font-size: 1.25rem;

$font-family-sans-serif: "Open Sans", Helvetica, Arial, sans-serif;

$fa-font-path:  "../libraries/webfonts";
$emoji-icon: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjc2Ljg5MXB4IiBoZWlnaHQ9IjI3Ni44OTFweCIgdmlld0JveD0iMCAwIDI3Ni44OTEgMjc2Ljg5MSIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjc2Ljg5MSAyNzYuODkxIg0KCSB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxjaXJjbGUgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2Utd2lkdGg9IjExIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGN4PSIxMzguNDQ1IiBjeT0iMTM4LjQ0NSIgcj0iMTMyLjk0NSIvPg0KPGNpcmNsZSBjeD0iNjguMTIiIGN5PSIxMjUuMzk1IiByPSIxNi41MDciLz4NCjxjaXJjbGUgY3g9IjIwOC42MTciIGN5PSIxMjUuMzk1IiByPSIxNi41MDgiLz4NCjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwMDAwMCIgc3Ryb2tlLXdpZHRoPSIxMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGQ9Ik02OC4xMiwxODIuMDM0DQoJYzAsMCw2OS43OTMsNzAuNzA0LDE0MC40OTgsMCIvPg0KPC9zdmc+DQo=';
//$card-spacer-y: 3rem;
$list-group-item-padding-y: 0.5rem;
$list-group-active-bg: #045ea7;

$popover-font-size: $font-size-base;
