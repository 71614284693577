
.footer {
  // position: absolute;
  // bottom:0;
  width:100%;
  min-height:160px;
  flex: 0 0 auto;
  color: white;
  background-color: #444646;

  ul {
    padding: 0;
    list-style-type: none;
  }

  a {
    color: #A8AAAF;
  }

  .participant-portal & {
    background-color: $primary;
    border-color: darken($primary, 6.5%);

    .dev & {
      background-color: $header-color-dev;
      border-color: darken($header-color-dev, 6.5%);
    }

    .staging & {
      background-color: $header-color-staging;
      border-color: darken($header-color-staging, 6.5%);
    }

    .testing & {
      background-color: $header-color-testing;
      border-color: darken($header-color-testing, 6.5%);
    }
  }
}
