.feedback {
  .scenarios {
    float: none;
    clear: both;
    width: 100%;

    .scenario {
      width: 100%;

      // copy some styles from cards (but notably not display: flex)
      margin-bottom: $spacer;
      background-color: $card-bg;
      border: $card-border-width solid $card-border-color;
      padding: $card-spacer-x;
      @include border-radius($card-border-radius);
      @extend .shadow-sm;

      .scenario_name {
        width: 500px;
        max-width: 100%;
      }

      // reduce horizontal padding to match vertical on up/down buttons
      .icons {
        position: relative;
        z-index: 99;

        .btn-link {
          padding: map-get($spacers, 1);
        }
      }

      &.highlighted {
        background-color: theme-color-level("info", $alert-bg-level);
      }

    }
  }

  .conditions, .then, .otherwise {
    margin-bottom: $form-group-margin-bottom;

    .header {
      font-size: $font-size-lg;
      font-weight: 700;
      margin-right: map-get($spacers, 3);
    }
  }

  .prizes {
    // Smush the prizes up closer with the eligible messages
    margin-top: -45px;
  }

  .conditions .header {
    margin-bottom: map-get($spacers, 2);
  }

  .separator {
    margin-top: $form-group-margin-bottom;
    margin-left: map-get($spacers, 4);
    font-weight: 700;
  }

  .action, .condition {
    margin-bottom: $form-group-margin-bottom;

    .updown {
      width: map-get($spacers, 4);
      float: left;
    }

    .body {
      background-color: $gray-lighter;
      margin-left: map-get($spacers, 4);
      padding: map-get($spacers, 3);

      div {
        margin-top: map-get($spacers, 2);
      }

      .substitution-strings {
        column-count: 3;
      }
    }
  }

  .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  select.form-control {
    max-width: 100%;
  }

  textarea.form-control {
    width: 100% !important;
    min-width: 50%;
    min-height: 100px;
    display: block;
  }
}

// Event logic display in event summaries etc.
.scenario {
  &.muted {
    @extend .text-muted;
    opacity: 50%;
  }

  .scenario-row {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &.muted {
      @extend .text-muted;
      opacity: 50%;
    }
  }

  .scenario-details {
    background-image: linear-gradient(#444, #444), linear-gradient(#444, #444), linear-gradient(#444, #444), linear-gradient(#444, #444);
    background-repeat: no-repeat;
    background-size: 8px 3px;
    background-position: top left, bottom left;
    border: solid #444;
    border-width: 0 0 0 3px;
    padding-top: 5px;
    padding-bottom: 5px;

    mark {
      line-height: 27px;
    }


  }

  &.well {
    margin-bottom: map-get($spacers, 2);
  }

  .scenario-title {
    padding: 0 5px 0 0;
    text-align: right;
    word-break: break-all;
  }
}

pre-wrap {
  display: block;
  padding: map-get($spacers, 2);
  margin: map-get($spacers, 2) 0;
  font-size: $font-size-sm;
  line-height: $line-height-base;
  white-space: pre-wrap;
  color: $pre-color;
  @extend .bg-light;
  border: 1px solid $card-border-color;
  border-radius: $border-radius;

  // Account for some code outputs that place code tags in pre tags
  code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0;
  }
}



// Not only used in event logic
.substitution-strings {
  column-count: 3;

  li,p {
    display: inline-block;
    width: 100%;
    border-radius: 5px;
    margin: 2px;
    padding: 5px;
    background-color: $gray-lighter;
    cursor: pointer !important;
    user-select: all;

    &:hover {
      background-color: $gray-light;
    }
  }
}
