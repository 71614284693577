@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid";

//  SMS inbox view
.inbox
{
  height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
  border-bottom: 2px solid rgba(0,0,0,0.2);

  div.date
  {
    clear: both;
    color: gray;
    text-align: center;
    font-size: 1.1em;
  }

  span.time
  {
    margin: 0 3px 0;
    float: right;
    color: gray;
  }

  div.sender
  {
    float: right;
    margin: 0 20px 0 0;
    font-size: 12px;
    color: #6c6f76;
    clear: both;
  }

  div.conversation {
    margin-top: 10px;
    box-shadow: 1px 1px 5px #f0f0f0;
    padding: 10px;
    overflow: auto;
    background-color: #efefef;
    border-left: 3px solid #000;
  }

  .object{

    &.fit-content {
      display: table;
      max-width: fit-content;
    }

    // background-color: #F2F2F2;
    border-radius: 5px;
    box-shadow: 0 0 6px #B2B2B2;
    display: inline-block;
    padding: 10px 18px;
    position: relative;
    vertical-align: top;
    clear: both;
    max-width: 450px;
    min-width: 150px;

    .inbox-img {
      width: 200px;
      margin: 20px 0;
      height: 200px;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      text-align: center;

      span {
        line-height: 200px;
      }
    }
    .inbox-img:hover {
      box-shadow: 0 0 2pt 1pt grey;
      cursor: pointer;
    }

    img {
      max-width: 400px;
    }

    // Diamond-shaped thing which becomes the left/right indicator on the chat bubble
    &::before {
      content: "\00a0";
      display: block;
      height: 16px;
      position: absolute;
      top: 11px;
      transform:           rotate( 29deg ) skew( -35deg );
        -moz-transform:    rotate( 29deg ) skew( -35deg );
        -ms-transform:     rotate( 29deg ) skew( -35deg );
        -o-transform:      rotate( 29deg ) skew( -35deg );
        -webkit-transform: rotate( 29deg ) skew( -35deg );
      width:  20px;
    }

    &.inbound, &.inbound-api {
      float: left;
      margin: 5px 45px 5px 20px;
      background-color: #bdf9bd;
      &::before
      {
        background-color: #bdf9bd;
        box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, .4);
        left: -9px;
      }
    }

    &.missed
    {
      float: left;
      margin: 5px 45px 5px 20px;
      background-color: #f9ecbd;
      &::before
      {
        background-color: #f9ecbd;
        box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, .4);
        left: -9px;
      }
    }
    &.outbound, &.outbound-api, &.outbound-reply {
      float: right;
      margin: 5px 20px 5px 45px;
      background-color: #e0f1ff;
      &::before {
        background-color: #e0f1ff;
        box-shadow: 2px -2px 2px 0 rgba(178, 178, 178, .4);
        right: -9px;
      }
    }

    &.manual {
      background-color: #badfff;

      &::before {
        background-color: #badfff;
      }
    }

    .status-icon-container:focus,
    .status-icon-container:hover {
      outline: none;
      text-decoration: none;
    }

    .popover {
      width: 276px; // Match the max-width specified by bootstrap
      .popover-content {
        overflow-wrap: break-word;
      }
    }
  }
}
.inbox.gallery
{
  .object
  {
    margin: 20px;
  }
}
.gallery-modal {
  .modal-body {
    min-height: 200px;

    .image-view {
      padding: 0 27px;
    }

    #prev-btn,
    #next-btn {
      cursor: pointer;
      position: absolute;
      top: 50%;
      width: auto;
      padding: 16px;
      margin-top: -50px;
      color: white;
      font-weight: bold;
      font-size: 20px;
      transition: 0.6s ease;
      border-radius: 0 3px 3px 0;
      user-select: none;
      -webkit-user-select: none;
      opacity: 0.3;
      background-color: #000000;
    }

    #prev-btn {
      left: 0;
      border-radius: 3px 0 0 3px;
    }

    #next-btn {
      right: 0;
      border-radius: 3px 0 0 3px;
    }

    #prev-btn:hover,
    #next-btn:hover {
      opacity: 0.8;
    }
  }
}
#uppy-select-files {
  position: absolute;
  z-index: 3;
  top: 0px;
  right: 35px;
  font-size: 22px;
}

/* Messaging Modes */
.messaging-mode {
  margin-top: 10px;
}

.alert[data-mode="Automated"] {
  @extend .alert;
  @extend .alert-success;
}

.alert[data-mode="Manual"] {
  @extend .alert;
  @extend .alert-warning;
}

.text-message.status-icon {
  @extend %fa-icon;
  @extend .fas;
  &.queued, &.pending, &.unknown, &.accepted, &.in-progress {
    // This copies only the rules for .text-info - not a.text-info or pseudoclasses etc
    @extend .text-info;

    &:before {
      content: fa-content($fa-var-info-circle);
    }
  }
  &.delivered, &.completed, &.sent, &.received {
    @extend .text-success;
    &:before {
      content: fa-content($fa-var-check);
    }
  }
  &.failed, &.undelivered {
    @extend .text-danger;
    &:before {
      content: fa-content($fa-var-exclamation-circle);
    }
  }
  &.busy, &.no-answer {
    @extend .text-warning;
    &:before {
      content: fa-content($fa-var-exclamation-triangle);
    }
  }
}

.voice-message.status-icon {
  @extend %fa-icon;
  @extend .fas;

  &.queued, &.unknown, &.initiated, &.ringing, &.in-progress {
    // This copies only the rules for .text-info - not a.text-info or pseudoclasses etc
    @extend .text-info;

    &:before {
      content: fa-content($fa-var-info-circle);
    }
  }
  &.completed {
    &:before {
      content: fa-content($fa-var-phone);
    }
  }
  &.busy, &.no-answer {
    &:before {
      content: fa-content($fa-var-phone-slash);
    }
  }
  &.failed {
    @extend .text-danger;
    &:before {
      content: fa-content($fa-var-exclamation-circle);
    }
  }
  &.voicemail {
    &:before {
      content: fa-content($fa-var-voicemail);
    }
  }

  &.conversation {
    &.completed {
      @extend .text-success;
      &:before {
        content: fa-content($fa-var-check);
      }
    }

    &.partial {
      @extend .text-warning;
      &:before {
        content: fa-content($fa-var-exclamation-triangle);
      }
    }

    &.missing {
      @extend .text-danger;
      &:before {
        content: fa-content($fa-var-times);
      }
    }
  }
}



