@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid";

// _LIST AND TABLES
// -------------------------
.table > tbody > tr > td {
	// vertical-align: middle;

	ul {
		margin-bottom: 0;
	}
}

// sidebar slider
.sidebar .slide-trigger {
  @extend %fa-icon;
  @extend .fas;

  position: absolute;
  top: 10px;
  right: 4px;
  cursor: pointer;
  font-size: $font-size-base;
  transform: rotateY(180deg);
  transition: transform 0.5s ease;
  &:before {
    content: fa-content($fa-var-sign-in-alt);
  }
  &:after {
    content: 'Filters';
    position: absolute;
    top: 18px;
    left: 4px;
    font-size: $font-size-base;
    text-transform: uppercase;
    font-weight: 700;
    width: 0;
    word-wrap: break-word;
    font-family: $font-family-monospace;
    line-height: 1em;
    opacity: 0;
    pointer-events: none;
    transition: opacity .5s ease-in-out;
  }
  &:hover {
    color: $gray;
  }

  //@media (max-width: @screen-sm-max) {
  //  display: none;
  //}
}
.sidebar.slide .slide-trigger {
  transform: none;
  &:after {
    opacity: 1;
    pointer-events: auto;
  }
}

.list-unstyled {
	.table > tbody > tr > td & {
		display: table-cell;
	}
}

.w2h-admin-list-pager-top {
  vertical-align: 35px;
}

.pagination {
  margin: 0;
}

.row-end {
  align-items: flex-end;
  display: flex;
  margin-bottom: 5px;
}

.col-end {
  display: flex;
  justify-content: flex-end;
}

.w2h-admin-boolean {
  .fa-check {
    color: $success;
  }
}

// Positioning of batch actions
.w2h-admin-generator.action-index .w2h-admin-list thead td {
  position: relative;
}

// don't wrap table headers in admin generators
.w2h-admin-generator .table > thead > tr > th {
  white-space: nowrap;
}

// tables that should scroll horizontally get wrapped in this
.table-scroll {
  overflow-x: auto;
  margin-bottom: $spacer;
  .table {
    margin-bottom: 0;
  }
}

// _SPECIFIC GENERATOR STYLES
// -------------------------

// manage data should push the content out
.dataAdmin .w2h-wrapper .container-fluid {
  flex: 1 0 auto;
}

// Specific styling for Study User History
.w2h-admin-text.StudyUserHistory-description {
  word-break: break-word;
}

// Overflow scroll in Manage Data
.module-dataAdmin.action-index #content-wrapper {
  overflow-x: auto;
}

// StudyUserAdmin -> Adherence Snapshot
.encounter-snapshot,
.data-snapshot
{
  // Get rid of table-striped and table-hover effect which are being applied from the outer Manage Participants table
  tr {
    background-color: inherit !important;
  }

  width: auto;
  thead th
  {
    border: none;
    padding: 0 2px;
  }
  td
  {
    border: none;
    padding: 0 2px 8px;
    vertical-align: top;
  }

  .encounter-date,
  .submission-date
  {
    width: 100%;
    height: 20px;
    text-align: center;
    font-size: 11px;
    color: #666;
  }

  .encounter,
  .submission
  {
    border: none;

    .this-encounter,
    .this-submission
    {
      width: 50px;
      height: 1.8em;
      text-align: center;
      line-height: 1.8em;
      margin: 0 auto;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .this-submission
    {
      margin-bottom: 10px;
    }

    .open
    {
      border: solid 1px black;
    }

    .encounter-name,
    .source-name
    {
      width: 120px;
      text-align: right;
      padding-right: 8px;
      word-wrap: break-word;
    }
  }
}

#w2h-fieldset-studies .sf_guard_user_StudyUsers {
  table {
    width: 100%;

    td, th {
      padding: 8px;
      vertical-align: top;
      border-top: 1px solid #ddd;
    }
  }
}

// Allow word wrap on the cohort dropdown items
[data-filter-field="cohort-id"] {
  li.search-choice {
    white-space: normal !important;
  }
  li.active-result {
    white-space: normal !important;
  }
}

// show page headers in SurveyJS when editing survey data
.module-dataAdmin {
  .sv_main .sv_row [id^="sp_"]:has(.sv_p_container):not([id$="_content"]) {
    border-top: 3px dashed #dee2e6;
    position: relative;
    padding-top: 0.5em;

    &:before {
      content: attr(name);
      color: #676a6c;
      position: absolute;
      background-color: white;
      left: 50%;
      top: -1em;
      padding: 0 5px;
      transform: translateX(-50%);
      font-weight: bold;
    }

    // undo the above for nested containers (e.g. panels)
    [id^="sp_"]:has(.sv_p_container):not([id$="_content"]) {
      border: none;
    }
    [id^="sp_"]:has(.sv_p_container):before {
      content: none;
    }
  }
}