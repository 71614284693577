.normalview {
  position: relative;
  padding-left: 0;
  list-style: none;
  @extend .clearfix;

  .list-group-item-heading {
    margin-bottom: 10px;
  }

  > li {
    margin-bottom: map-get($spacers, 2);
  }

  .normalview-actions {
    position: absolute;
    top: 0;
    right: 0;

    // these are actions within normalview that toggle when editview expands and normalview remains
    .editview-action {
      display: none;
    }
  }

  &.reload {
    opacity: 0.5;
    pointer-events: none;
  }

  .normalview-header {
    margin-top: 0;
    h1, h2, h3, h4, h5, h6 {
      margin-top: 0;
    }
  }
}

.editview,
.duplicateview {
  display: none;
  position: relative;
  padding-left: 0;
  list-style: none;
  @extend .clearfix;

  &.errors {
    display: block;
  }
  &.refresh {
    display: block;
    opacity: .5;
  }

  .list-group-item-heading {
    margin-bottom: 10px;
  }

  .content {
    padding-top: 30px;
    .container .row:not(:first-child) {
      padding-top: 20px;
    }
  }
  // these actions are not in normalview, and don't toggle
  .editview-actions,
  .duplicateview-actions {
    position: absolute;
    top: 0;
    right: 0;
  }
}