.event-pager {
  margin-top: 20px;
}
.pager-link
{
  text-align: center;
}

$deleted-gray: darken($gray-lighter, 6%);

.event {
  &.list-group-item {
    &.past {
      background-color: $list-group-hover-bg;
    }
    &.today {
      color: theme-color-level("info", $alert-color-level);
      background-color: theme-color-level("info", $alert-bg-level);
    }
    &.deleted {
      background-color: $deleted-gray;
      small {
        color:#000000;
      }
    }
  }

  .past {
    background-color: $list-group-hover-bg;
  }
  .today {
    color: theme-color-level("info", $alert-color-level);
    background-color: theme-color-level("info", $alert-bg-level);
  }

  .deleted {
    background-color: $deleted-gray;
  }

  .events-container {
    .list-group {
      margin-bottom: 4px;
    }
    .list-group-item {
      ul.normalview {
        padding: 0 5px;
      }
    }
  }

  .normalview,
  .editview,
  .duplicateview {


    .repeat {
      display: block;
      color: $link-color;
    }
    .repeat ~ .repeat
    {
      border-top: 1px dotted $link-color;
      margin-top: 5px;
      padding-top: 5px;
    }
    .alert {
      margin-top: 5px;
    }
    // do not add padding if viewed on a smaller screen
    @include media-breakpoint-up(sm) {
      padding: 0 5px 0 51px;

      &.SurveyTemplateEvent, &.OfficeTemplateEvent {
        background: url(../../wayToHealthPlugin/images/event-clipboard.png) no-repeat 5px 0;
      }

      &.DeviceTemplateEvent {
        background: url(../../wayToHealthPlugin/images/event-graph.png) no-repeat 5px 0;

        &.messaging {
          background: url(../../wayToHealthPlugin/images/event-comment-red.png) no-repeat 5px 0;
        }
      }

      &.ConversationTemplateEvent {
        background: url(../../wayToHealthPlugin/images/event-comment-red.png) no-repeat 5px 0;
      }

      &.VoiceConversationTemplateEvent {
        background: url(../../wayToHealthPlugin/images/event-phone.png) no-repeat 5px 0;
      }

      &.AlertTemplateEvent {
        background: url(../../wayToHealthPlugin/images/event-comment-blue.png) no-repeat 5px 0;
      }

      &.incidentBlock {
        background: url(../../wayToHealthPlugin/images/event-alert.png) no-repeat 5px 0;
      }

      &.LotteryTemplateEvent, &.HabitFormationLotteryTemplateEvent, &.HybridSweepstakesLotteryTemplateEvent {
        background: url(../../wayToHealthPlugin/images/event-moneybag.png) no-repeat 5px 0;
      }

      &.LogicTemplateEvent {
        background: url(../../wayToHealthPlugin/images/event-branch.png) no-repeat 5px 0;
      }
    }
  }

  .event-incidents {
    .normalview {
      border-top: 1px dotted $list-group-border-color;
      padding-top: $spacer;

      .normalview-actions {
        top: $spacer;
      }
    }
  }
}

.eventBlock.deleted {
  background-color: $deleted-gray;
}

#events,
#event-new {
  .editview {
    .list-group-item-heading {
      margin-bottom: 10px;
    }

    .form-group .form-group > label {
      font-size: $font-size-sm;
    }

    .substitution-strings {
      column-count: 3;
    }

    .time-options {
      position: absolute;
      left: 0px;
      overflow: auto;
      z-index: 10000;
      width: 100px;
      height: 150px;
      background: #ffffff;
      padding: 5px;
      border: 1px solid #dddddd;
      border-radius: 3px;
      box-shadow: rgba(0,0,0,0.1) 0px 0px 6px;

      li {
        cursor: pointer;
      }
    }
  }
}

span.event-tag
{

  font-size: 12px;
  text-align: center;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  padding: 1.5px 8px;
  margin-right: 5px;

  &.skipped
  {
    color: #FF0000;
    background-color: #FFF;
    border-color: #FF0000;
  }

  &.completed
  {
    color: #FFF;
    background-color: #333333;
    border-color: #333333;
  }

  &.late
  {
    color: #FFF;
    background-color: #890082;
    border-color: #890082;
  }

  &.pending
  {
    color: #AAA;
    border-color: #AAA;
  }

  &.compliant
  {
    color: #FFF;
    background-color: #149717;
    border-color: #149717;
  }

  &.manual
  {
    color: #FFF;
    background-color: #666666;
    border-color: #666666;
  }

  &.deleted
  {
    color: #FFF;
    background-color: $danger;
    border-color: $danger;
  }

  &.future
  {
    display: none;
  }

  &.active
  {
    display: none;
  }
}

// leave room for actions in arm overview
#events.overview .event li.name {
  padding-right: 240px;
}

#events.overview {
  .add_comment {
    position: absolute;
    top: 3em;
    left: 7px;
    line-height: 1em;
    border-radius: 50%;
    width: 35px;
    height: 35px;
  }

  .normalview:not(:hover) .add_comment {
    display: none;
  }
  .normalview:hover .add_comment {
    color: black !important;
  }
}

//@import (reference) "../../plugins/wayToHealthPlugin/lib/bootstrap/less/list-group.less";
#events .scheduled-start-date {
  @extend .list-group-item, .list-group-item:first-child;
}

#events .block-empty {
  background-color: #eee;
}

body.module-arm #content-wrapper {
  .sidebar {
    flex-basis: 300px;
  }

  .sidebar + .container-fluid {
    max-width: calc(100% - 300px);
  }
}

.participant-event-details.schedule {
  .repeat {
    display: block;
    color: $link-color;
  }
}

.incentive-metadata {
  max-width: 500px;
  label {
    margin-bottom: 0;
    font-size: .75rem;
  }
  h4 {
    font-size: 1rem;
  }
  blockquote {
    margin-left: 0;
    margin-bottom: 0.25rem;
    font-size: 1em;
  }
}
