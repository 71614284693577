@import "variables";

@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";

// legacy variables we use in our themes, easier to remember than "what number gray did we use again?"
$gray-darker: $gray-900;
$gray-dark: $gray-800;
$gray: $gray-600;
$gray-light: $gray-500;
$gray-lighter: $gray-300;

$light: $gray-300;

$header-color-dev: $green;
$header-color-staging: $danger;
$header-color-testing: $yellow;


@import "typography";
@import "layout";
@import "navigation";
@import "footer";
@import "alert-bar";
@import "widgets";
@import "participant";
@import "inbox";
@import "forms-theme";
@import "normalview";
@import "admin-generators";
@import "videos";
@import "study-guide";
@import "devices";
@import "enrollment";
@import "events";
@import "accordions";
@import "epic";
@import "event-logic";
@import "participant-portal";
@import "qualtrics";
@import "surveyjs";
@import "uploadParticipants";
@import "triage";

.btn {
  white-space: nowrap;
}

.btn-default {
  @include button-variant($white, $light);
}

label {
  font-weight: bold;

  .form-check &,
  .form-check-inline & {
    font-weight: normal;
  }
}

.well {
  @extend .card;
  @extend .card-body;
  @extend .bg-light;
}

.list-group-item {
  &.disabled {
    cursor: not-allowed;
    background-color: $gray-lighter;
  }
}

// bs4 no longer has secondary text in headers
h1, h2, h3, h4, h5, h6 {
  &.list-group-item-heading {
    margin-top: 0;
  }

  small, .small {
    font-weight: normal;
    line-height: 1;
    color: $gray-600;
  }
}

h1, h2, h3 {
  small, .small {
    font-size: 65%;
  }
}

h5 {
  font-size: $font-size-base;
}

h4, h5, h6 {
  small, .small {
    font-size: 75%;
  }
}

.tooltip-link {
  text-decoration: underline dotted;
  color: $link-color;
  cursor: help;
}

.blockquote {
  // in bs4 they removed the left border on blockquotes
  padding-left: 20px;
  margin-left: 20px;
  border-left: 5px solid $gray-lighter;
}

.lens-dashboard {
  width: 100%;
  height: 6000px;
  border: 0;
}

.opacity-70 {
  opacity: 0.7;
}

.btn-unstyled {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}


.anim-spin {
  -webkit-animation: spin 1000ms infinite linear;
  animation: spin 1000ms infinite linear;
}

.relative {
  position: relative;
}

// wide popovers, used for guided tour
.popover.popover-wide {
  max-width: 500px;
}

// Larger sized icon. Used for icons on error check pages
.icon-lg {
  font-size: 1.5em;
  padding-right: 5px;
}



// If we put a label inside a header (e.g. to add a beta tag), make it smaller.
h1, h2, h3 {
  .label {
    vertical-align: super;
    font-size: 0.3em;
  }
}


// use for dividing sections on a single page
section {
  margin-top: map-get($spacers, 3);
  margin-bottom: map-get($spacers, 4);
  border-bottom: 1px solid $hr-border-color;

  > h1:first-child,
  > h2:first-child,
  > h3:first-child,
  > h4:first-child,
  > h5:first-child,
  > h6:first-child {
    margin-top: 0;
  }

  &.section-last {
    margin-bottom: 0;
    border-bottom: none;
  }
}


.list-group-item span[aria-expanded=true].fa-chevron-down {
  display: none;
}
.list-group-item span[aria-expanded=false].fa-chevron-up {
  display: none;
}
.participant-enrollment .list-group-item.event.active {
  z-index: auto !important;
}

.list-group-numbered {
  counter-reset: list-group-number;

  .list-group-item {
    &::before {
      counter-increment: list-group-number;
      content: counter(list-group-number) ".";
      float: left;
      font-size: $h3-font-size;
      color: $gray-600;
      line-height: $headings-line-height;
    }

    > * {
      padding-left: 40px;
      margin-top: 0;
    }
  }
}

h1 > .fas,
h2 > .fas,
h3 > .fas,
h4 > .fas,
h5 > .fas,
h6 > .fas {
  line-height: 0.9em;
}

.no-transitions {
  transition: none !important;
}

.ui-sortable .sort:hover {
  cursor: move;
  background-color: #e0e1e3;
}

// Event Calendar
.fc .fc-toolbar .fc-button {
  @extend .btn-default;
  &.fc-button-active {
    @extend .btn-default.active;
  }
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
@media /* devices older than iPhone 5: */
screen and (device-aspect-ratio: 2/3),
  /* iPhone 5, 5C, 5S, iPod Touch 5g */
screen and (device-aspect-ratio: 40/71),
  /* iPhone 6, iPhone 6s, iPhone 7  */
screen and (device-aspect-ratio: 375/667),
  /* iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
screen and (device-aspect-ratio: 9/16) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="url"] {
    font-size: 16px;
  }
}

/* Override style from dataTables */
table.dataTable {
  border-collapse: collapse !important;
}

.cursor-pointer {
  cursor: pointer;
}