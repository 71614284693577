

/*GLOBAL ALERT BAR*/

ul.w2h-global-alert-bar
{
  padding-left: 0;
  list-style: none;
  margin: 0;
  -moz-box-shadow:inset 0px 0px 5px rgba(0,0,0,0.5);
  -webkit-box-shadow:inset 0px 0px 5px rgba(0,0,0,0.5);
  box-shadow:inset 0px 0px 5px rgba(0,0,0,0.5);
  li.w2h-global-alert a
  {
    display:block;
    float:none;
    color:#ffffff;
    font-size:15px;
    padding:7px 0px 7px 20px;
    list-style:none;
    padding-left:20px;
    background-color:#e10c26;
    border-bottom:1px solid #b1091e;
    text-shadow:0px 1px #b1091e;
    position:relative;
    background:#e10c26 url(../../wayToHealthPlugin/images/white-alert.png) no-repeat 0px 8px;
  }
  li.w2h-global-alert a:hover
  {
    color:#ffffff;
    color:rgba(255,255,255,0.9);
    background-color:#c90b22;
    text-decoration:none;
  }
  li.w2h-global-alert a::last-child
  {
    border-bottom:0px solid #999999;
  }
  li.w2h-global-alert a.notice
  {
    display:block;
    float:none;
    color:#383101;
    font-size:15px;
    padding:7px 0px 7px 20px;
    list-style:none;
    padding-left:20px;
    background-color:#fadc0b;
    border-bottom:1px solid #ceb404;
    text-shadow:0px 1px #fcea6f;
    position:relative;
    background:#fadc0b url(../../wayToHealthPlugin/images/white-alert.png) no-repeat 0px 8px;
    background-image:url(../../wayToHealthPlugin/images/black-alert.png);
    margin-bottom: 0px;
  }
  li.w2h-global-alert a.notice:hover
  {
    color:#383101;
    color:rgba(56,49,1,0.9);
    background-color:#e7ca05;
    text-decoration:none;
  }
  li.w2h-global-alert a.notice::last-child
  {
    border-bottom:0px solid #999999;
  }
}
